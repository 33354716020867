var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"home",attrs:{"role":"main"}},[_c('div',{staticClass:"main__header"},[_c('div',{staticClass:"main__header__item left"}),_c('div',{staticClass:"main__header__item"},[_c('h1',[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"main__header__item right"})]),_c('div',{staticClass:"main__header main__header--secondary"},[_c('div',{staticClass:"toper__item left"},[_c('mq-layout',{staticClass:"small-buttons",attrs:{"mq":['md', 'lg', 'xlg', 'xl']}})],1),_c('div',{staticClass:"toper__item"},[_c('div',{staticClass:"button-group"},[_c('button',{class:{ active: _vm.$store.state.data.trades.sort2 === 'today' },attrs:{"type":"button"},on:{"click":function($event){return _vm.setSortType('today')}}},[_c('span',[_vm._v("Сегодня")])]),_c('button',{class:{ active: _vm.$store.state.data.trades.sort2 === 'tomorrow' },attrs:{"type":"button"},on:{"click":function($event){return _vm.setSortType('tomorrow')}}},[_c('span',[_vm._v("Вчера")])]),_c('button',{class:{ active: _vm.$store.state.data.trades.sort2 === 'week' },attrs:{"type":"button"},on:{"click":function($event){return _vm.setSortType('week')}}},[_c('span',[_vm._v("Неделя")])]),_c('button',{class:{ active: _vm.$store.state.data.trades.sort2 === 'month' },attrs:{"type":"button"},on:{"click":function($event){return _vm.setSortType('month')}}},[_c('span',[_vm._v("Месяц")])]),_c('button',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.vcoConfig),expression:"vcoConfig"}],ref:"buttonPeriod",staticClass:"widgets__table__field",class:{
            active:
              _vm.$store.state.data.trades.sort2 === 'period' &&
              _vm.$store.state.data.trades.sortDate,
          },attrs:{"type":"button"},on:{"click":function($event){_vm.openPeriod = true}}},[_c('i',[_c('CalendarIcon')],1),_c('span',[_vm._v("Период")]),_c('b',{directives:[{name:"show",rawName:"v-show",value:(_vm.openPeriod),expression:"openPeriod"}],staticClass:"date-picker"},[_c('date-picker',{ref:"pickerPeriod",attrs:{"inline":"","range":""},model:{value:(_vm.$store.state.data.trades.sortDate),callback:function ($$v) {_vm.$set(_vm.$store.state.data.trades, "sortDate", $$v)},expression:"$store.state.data.trades.sortDate"}})],1)])])]),_c('div',{staticClass:"toper__item right"},[_c('mq-layout',{staticClass:"small-buttons",attrs:{"mq":['xs', 'sm', 'xsm']}},[_c('a',{staticClass:"btn btn--small",attrs:{"href":"#"}},[_c('PhoneIcon')],1),_c('a',{staticClass:"btn btn--small",attrs:{"href":"#"}},[_c('InternetIcon')],1)]),_c('div',{staticClass:"small-buttons"},[_c('a',{staticClass:"btn btn--small btn--white",attrs:{"href":"#"}},[_c('i',[_c('RefreshIcon')],1)])])],1)]),_c('div',{staticClass:"main__content"},[_c('div',{staticClass:"tbl"},[(_vm.$store.state.data.trades.showFilter)?_c('div',{staticClass:"tbl__header"},[_c('div',{staticClass:"tbl__filter"},[_c('label',{staticClass:"tbl__filter__item"},[_c('v-select',{attrs:{"appendToBody":"","options":_vm.filterDropName.map(function (item) { return ({
                  value: item.id,
                  label: item.name,
                }); }),"placeholder":"Сотрудник","value":_vm.filter.responsible_user_id
                  ? {
                      value: _vm.filter.responsible_user_id,
                      label: _vm.filterDropName.find(
                        function (s) { return s.id === _vm.filter.responsible_user_id; }
                      ).name,
                    }
                  : null},on:{"input":_vm.onUserChange},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
                  var attributes = ref.attributes;
return [_c('div',_vm._b({},'div',attributes,false),[_c('DownIcon')],1)]}}],null,false,3765746126)})],1),_c('label',{staticClass:"tbl__filter__item"},[_c('v-select',{attrs:{"appendToBody":"","options":_vm.filterDropGroup.map(function (item) { return ({
                  value: item.id,
                  label: item.name,
                }); }),"placeholder":"Подразделение","value":_vm.filter.group_id
                  ? {
                      value: _vm.filter.group_id,
                      label: _vm.filterDropGroup.find(
                        function (s) { return s.id === _vm.filter.group_id; }
                      ).name,
                    }
                  : null},on:{"input":_vm.onGroupChange},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
                  var attributes = ref.attributes;
return [_c('div',_vm._b({},'div',attributes,false),[_c('DownIcon')],1)]}}],null,false,3765746126)})],1)]),_c('div',{staticClass:"tbl__settings"},[_c('a',{staticClass:"tbl__setting",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.switchColumnModal.apply(null, arguments)}}},[_c('SettingsIcon')],1)])]):_vm._e(),_c('vue-good-table',{attrs:{"max-height":"65vh","columns":_vm.$store.state.data.analytics.columns,"rows":_vm.reportData.length
            ? [
                Object.assign({}, {name: 'Итого'},
                  _vm.totals,
                  {children: _vm.reportData}) ]
            : [],"styleClass":"vgt-table","group-options":{
          enabled: true,
          headerPosition: 'bottom',
        },"pagination-options":{
          enabled: true,
          perPage: 15,
          nextLabel: '',
          prevLabel: '',
          rowsPerPageLabel: 'Вывести',
          ofLabel: 'из',
          pageLabel: 'страниц',
          allLabel: 'Все',
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field.includes('Summ'))?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip:bottom",value:(((props.column.label) + " / " + (props.row.name))),expression:"`${props.column.label} / ${props.row.name}`",arg:"bottom"}]},[_vm._v(_vm._s(_vm.prettifyPrice(props.row[props.column.field])))]):_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip:bottom",value:(((props.column.label) + " / " + (props.row.name))),expression:"`${props.column.label} / ${props.row.name}`",arg:"bottom"}]},[_vm._v(_vm._s(props.formattedRow[props.column.field] || '-'))])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[(_vm.$apollo.loading)?_c('mq-layout',{attrs:{"mq":['lg', 'xlg', 'xl']}},_vm._l((_vm.$store.state.me.role === 'manager' ? 1 : 5),function(row){return _c('div',{key:row + 100,staticStyle:{"display":"flex","flex-direction":"row","flex-wrap":"nowrap","justify-content":"space-between","height":"70px"}},_vm._l((_vm.$store.state.data.analytics.columns.filter(function (elem) { return !elem.hidden; }).length),function(block,index){return _c('div',{key:index,staticClass:"sceleton"},[_c('span',{staticStyle:{"opacity":"0"}},[_vm._v(_vm._s(_vm.$store.state.data.analytics.columns.filter(function (elem) { return !elem.hidden; })[index].label))])])}),0)}),0):_c('div',[_vm._v("Данных нет")]),_c('mq-layout',{attrs:{"mq":['sm', 'xsm']}},[(_vm.$apollo.loading)?_c('div',{staticClass:"messages__status"},[_vm._v("Идёт загрузка данных...")]):_c('div',[_vm._v("Данных нет")])])],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }